import React, { useState } from 'react'
import '../assets/css/Style.css'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { base_url } from '../Config/config'
import _fetch from '../Config/api'
import toast from 'react-hot-toast'
import { ColorRing } from 'react-loader-spinner';
import businessman from '../assets/img/businessman.png'
import logo from '../assets/img/logo.png'
const SignUp = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sponserIdFromQuery = queryParams.get('sponserId');
    const [email, setEmail] = useState();
    const [Phone, setPhone] = useState();
    const [password, setPassword] = useState();
    const [confirmpassword, setConfirmpassword] = useState();
    const [name, setName] = useState();
    const [sponser, setSponser] = useState(sponserIdFromQuery || '');
    // const [user, setUser] = useState('HKJ89');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showconfirmPassword, setShowConfirmPassword] = useState(false);
    // const [showPasswords, setShowPasswords] = useState(false);


    const handleSignUp = async (e) => {
        e.preventDefault();
        if (password !== confirmpassword) {
            toast.dismiss();
            toast.error("Password and confirm password do not match");
            return;
        }
        if (!email || !password || !name || !sponser || !confirmpassword || !Phone) {
            toast.dismiss()
            toast.error("Please enter the details");
            return;
        }
        if (Phone.length !== 10) {
            toast.dismiss();
            toast.error("Phone number should be exactly 10 digits");
            return;
        }

        setLoading(true);

        try {
            const signupData = {
                email: email,
                password: password,
                phoneNumber: Phone,
                name: name,
                sponserId: sponser,
                traderId: 'WH12H',
            };
            console.log('player signup', signupData);

            let response = await _fetch(`${base_url}api/user/signUp`, 'POST', signupData, {})

            // console.log("response", response);
            if (response.success === true) {
                toast.dismiss()
                toast.success(response.message);
                navigate('/Verfication', { state: { email: email } });
            } else {
                toast.dismiss()
                toast.error(response.message);
            }
        }
        catch (error) {
            console.log("Error details", error);

        } finally {
            setLoading(false);
        }
    }

    // useEffect(() => {
    //     // fetchData();
    //     if (sponser) {
    //         handlebalance();
    //     }
    // }, [sponser_id])

    return (
        <>
            <div className="conatiner-fluid">
                <div className="d-flex justify-content-center main-box-sing">
                    <div className="sign-page ">

                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12 sinup-bg'>
                                <div className="heading text-center mb-3 mt-3">

                                    <NavLink to='/'><img className="welcome" src={logo} alt="" />

                                    </NavLink>
                                </div>
                                {/* <img className="image_border mt-3" src={businessman} alt="..." /> */}

                                <form >

                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Sponsor Code</label>
                                        <input type="text" id="sponser" value={sponser} onChange={(e) => setSponser(e.target.value)} autoComplete='off' className="form-control" />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Name </label>
                                        <input type="text" id="name" onChange={(e) => setName(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" />
                                    </div>



                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Email </label>
                                        <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Phone </label>

                                        <div className="">
                                            <input type="tel" className="form-control" value={Phone} onChange={(e) => setPhone(e.target.value)} />
                                            {/* <span className="input-group-addon">Tel</span> */}
                                        </div>
                                    </div>


                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>

                                        {/* <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} autoComplete='off' className="form-control" /> */}
                                        <div class="input_eye">
                                            <div className='in_put_f'>
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    id='password'
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    className="form-control "
                                                />

                                            </div>

                                            <div className='eye_sec'>
                                                <span
                                                    className={`password-toggle ${showPassword ? 'active' : ''}`}
                                                    onClick={() => setShowPassword(!showPassword)}

                                                >
                                                    {showPassword ? (
                                                        <i className="fas fa-eye-slash"></i>
                                                    ) : (
                                                        <i className="fas fa-eye"></i>
                                                    )}
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Confirm Password</label>

                                        {/* <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} autoComplete='off' className="form-control" /> */}
                                        <div class="input_eye">
                                            <div className='in_put_f'>
                                                <input
                                                    type={showconfirmPassword ? 'text' : 'password'}
                                                    id='password'
                                                    value={confirmpassword}
                                                    onChange={(e) => setConfirmpassword(e.target.value)}
                                                    className="form-control "
                                                />

                                            </div>

                                            <div className='eye_sec'>
                                                <span
                                                    className={`password-toggle ${showconfirmPassword ? 'active' : ''}`}
                                                    onClick={() => setShowConfirmPassword(!showconfirmPassword)}

                                                >
                                                    {showconfirmPassword ? (
                                                        <i className="fas fa-eye-slash"></i>
                                                    ) : (
                                                        <i className="fas fa-eye"></i>
                                                    )}
                                                </span>
                                            </div>

                                        </div>
                                    </div>




                                    <button type="submit" className="btn btn-primary d-block m-auto" onClick={handleSignUp}>
                                        {loading ? (
                                            <ColorRing
                                                height={20}
                                                width={20}
                                                color="#fff"
                                                ariaLabel="loading"
                                            />
                                        ) : (
                                            'Submit'
                                        )}
                                    </button>
                                    <div>
                                        <div className="small fw-bold mt-2 pt-1 mb-0 text-center">
                                            Have an account? <NavLink to='/Login' className="link-danger">Login</NavLink>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <img className="image_border  slideskew img-fix bitcoin-img sd-shape7 mt-3" src={businessman} alt="..." />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp