import React from 'react'
import Navbar from './Navbar'
import './home.css'
import { NavLink } from 'react-router-dom'
import { FaChevronRight } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import Footer from './Footer';
import part3 from '../assets/img/part3.png'
import part2 from '../assets/img/part2.png'
import part1 from '../assets/img/part1.png'
import Footeres from './Footeres'
const Home = () => {
    return (
        <>
            <div className="main">
                <Navbar />
                <main className='main' style={{ overflowX: "hidden" }}>
                    <section className='foreign_exchange'>
                        <div className="container container_p">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                                    <h1 className='heading_h1 fw-bold'>
                                        What is AI Trade ?
                                        {/* <span className="primary_cl">Trades Goal? </span> */}

                                    </h1>
                                    <p className="pt-3 text-muted primary_para">
                                        AI Trade, or Artificial Intelligence in Trading, refers to the application of artificial intelligence technologies, such as machine learning, natural language processing (NLP), and data analytics, to enhance trading processes in financial markets. These systems are designed to analyze market data, identify profitable opportunities, and execute trades automatically or with minimal human intervention.
                                    </p>
                                    <p className="text-muted primary_para">

                                        AI trading systems are widely used in various asset classes, including stocks, forex, cryptocurrencies, and commodities.AI Trade, or Artificial Intelligence in Trading, is a transformative approach that leverages the power of AI technologies to analyze financial markets, predict price movements, and execute trades.                                    </p>
                                    <p className="text-muted primary_para">By utilizing advanced machine learning algorithms, natural language processing (NLP), and data analytics, AI Trade enhances decision-making in trading, making it more efficient and accurate. This innovative technology processes massive amounts of market data, including historical prices, real-time news, and economic trends, to identify patterns and opportunities that might be overlooked by human traders.</p>

                                </div>

                                <div className=" col-lg-5 col-md-5 col-sm-12 col-12 m-auto">
                                    <img className="" src={part3} alt="" className='img-fluid animation-img' />
                                </div>

                            </div>
                        </div>
                    </section>


                    <section className='' data-aos="flip-left">
                        <div className="container">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                {/* <h1><span className='primary_cl' style={{ fontSize: "20px" }}>Future-trade Feature
                                </span></h1> */}
                                <h1 className="pt-2 heading_h1 fw-bold">Best Features </h1>
                                <p className="pt-2 text-muted"> AI systems can execute trades autonomously or provide actionable insights to traders, helping them optimize strategies across various asset classes such as stocks, cryptocurrencies, forex, and commodities.

                                </p>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-1.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>Benefits of AI Trade                                                </h5>
                                                <p className='text-muted mt-3'>Speed: Executes trades faster than humans can, maximizing opportunities.
                                                    Accuracy: Reduces errors caused by human judgment or emotion.
                                                    Data-Driven Decisions: Uses large datasets to make informed decisions.
                                                    24/7 Operation: AI systems can monitor and trade in markets around the clock.


                                                </p>
                                            </div>
                                        </div>
                                        <div className="card_hover_div d-none">
                                            We manage risk by offering a binding exchange service that allows users to set advanced order types, such as stop-loss and take-profit orders. These orders help manage risk by automatically closing positions when certain price levels are reached, minimizing potential losses. Additionally, through our service, users can control larger positions with a smaller amount of capital by utilizing leverage, giving them greater flexibility in managing their trades.</div>
                                    </div>

                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-2.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>Applications of AI Trade                                                </h5>
                                                <p className='text-muted mt-3'>Stock Markets: AI is used for equity trading and portfolio management.
                                                    Forex Trading: Predicts currency movements with high precision.
                                                    Cryptocurrency Trading: AI bots navigate volatile crypto markets.
                                                    Commodity Trading: Analyzes supply, demand, and global events affecting commodities.</p>
                                            </div>
                                        </div>
                                        <div className="card_hover_div d-none">
                                            An internal market structure break is simply when there is a minor break in structure in between the major swing highs and lows, indicating a shift in orderflow.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-3.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>Challenges in AI Trade                                                </h5>
                                                <p className='text-muted mt-3'>Despite its advantages, AI Trade is not without challenges. The effectiveness of AI largely depends on the quality and quantity of data it processes. Incomplete or inaccurate data can lead to erroneous predictions and financial losses. Market volatility, particularly in events like financial crises or geopolitical tensions, can also challenge AI systems, as they rely on historical data to predict future trends.</p>
                                            </div>
                                        </div>
                                        <div className="card_hover_div d-none">
                                            Crypto Future trading, the common name for the foreign exchange market, entails trading currencies instead of securities (think the U.S. dollar, European euro or Japanese yen).
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-4.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>Future Prospects of AI Trade</h5>
                                                <p className='text-muted mt-3'>The future of AI Trade is poised to reshape financial markets further. Advancements in AI technologies, such as deep learning and quantum computing, promise even greater predictive accuracy and processing capabilities. The integration of AI with blockchain technology could enhance transparency and security in trading, while decentralized AI platforms may democratize access to advanced trading tools.</p>
                                            </div>
                                        </div>
                                        <div className="card_hover_div d-none">
                                            In conclusion, Crypto Future trading can be a legitimate and profitable form of investment, but it is important to be aware of the potential for scams.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-5.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>How can you level up?</h5>
                                                <p className='text-muted mt-3'>A user can level up by building a larger team, either through direct referrals or by growing their team with indirect referrals from existing members.</p>
                                            </div>
                                        </div>
                                        <div className="card_hover_div d-none">
                                            Once negotiations are completed, Foreign Exchange Contract Insurance allows the company to set the forward rate of the currency in order to stabilise proceeds in that currency.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-6.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>Do we need to monitor and execute trades in future bot?

                                                </h5>
                                                <p className='text-muted mt-3'>future bot is a tool that automates futures trading, allowing traders to profit from price movements in cryptocurrency or other assets without having to manually monitor and execute trades.



                                                </p>
                                            </div>
                                        </div>
                                        {/* <div className="card_hover_div">
                                            A Crypto Future expert advisor is an automated system, which monitors the Crypto Future markets and provides signals upon identifying trading opportunities.
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='future-trade' data-aos="fade-left">
                        <div className="container container_p">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4 d-flex justify-content-center align-items-center">
                                    <img src={part2} alt="" className='img-fluid' />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                                    <h1 className='heading_h1 fw-bold'>How AI Trade Works?

                                    </h1>
                                    <p className="pt-3 text-muted primary_para">AI Trade operates through a series of well-defined steps that harness the capabilities of artificial intelligence. The process begins with data collection, where the AI system gathers historical and real-time market data, such as price charts, economic reports, and news articles. This data is processed using big data analytics, cleaning and organizing it for accurate analysis. Machine learning algorithms then analyze this data, identifying market trends, patterns, and potential trading opportunities.</p>
                                    <p className="pt-3 text-muted primary_para"> These algorithms improve over time, adapting to new information and refining their predictions.

                                        Once the analysis is complete, AI systems generate buy, sell, or hold signals based on predefined strategies or rules set by the user. In advanced setups, AI bots can automatically execute trades, ensuring precision and speed. For instance, in high-frequency trading, AI can place thousands of trades within seconds, capitalizing on even minor market fluctuations.
                                    </p>
                                </div>

                            </div>


                        </div>
                    </section>

                    <section className='w_ft' data-aos="flip-up">
                        <div className="container">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <h1 className="fw-bold heading_h1">Key Features of AI Trade                                </h1>
                                {/* <p className="pt-2 text-muted primary_para">Here's a brief overview of how Crypto Future trading works:</p> */}
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                                    {/* <h1>We’ve built a platform to buy and sell shares.
                                    </h1> */}
                                    <div className="timeline mt-3">
                                        <div className="timeline_box mt-4">
                                            <h3 className='Sentiment'>Market Analysis</h3>
                                            <div className="pt-2 text-muted">AI can process vast amounts of historical and real-time data to identify market patterns, trends, and signals.</div>
                                        </div>
                                        <div className="timeline_box mt-4">
                                            <h3 className='Sentiment'>Predictive Modeling</h3>
                                            <div className="pt-2 text-muted">Using machine learning, AI can predict future market movements based on past data and emerging trends.</div>
                                        </div>
                                        <div className="timeline_box mt-4">
                                            <h3 className='Sentiment'>Automated Trading</h3>
                                            <div className="pt-2 text-muted">AI systems can execute trades automatically based on predefined criteria, eliminating human emotion and improving speed.
                                            </div>
                                        </div>
                                        <div className="timeline_box mt-4">
                                            <h3 className='Sentiment'>Sentiment Analysis</h3>
                                            <div className="pt-2 text-muted">AI tools can analyze news, social media, and market sentiment to predict the impact of events on asset prices.</div>
                                        </div>
                                        {/* <div className="timeline_box mt-4">
                                            <h3>Going long and going short
                                            </h3>
                                            <div className="pt-2 text-muted">Traders have the option to either buy (go long) a currency pair if they believe its value will rise or sell (go short) a currency pair if they think its value will fall.
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                                    <img src={part1} alt="" className='img-fluid img_scale' />
                                </div>

                            </div>


                        </div>
                    </section>




                    {/* <section cla
                    
                    
                    ssName="faqs" data-aos="zoom-in-left">
                        <div className="container">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div><span className="primary_cl">FAQS</span></div>
                                <h1 className='fw-bold'>Frequently Asked questions</h1>
                                <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.
                                </p>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                                    <img src="https://future-trade.io/vendor/images/faq_img002.png" alt="" className='img-fluid' />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                                    <div className="tabs">
                                        <ul class="nav nav-tabs">
                                            <li class="nav-item">
                                                <a class="nav-link tab_links active" href="#onetab" data-bs-toggle="tab">Genral</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#twotab" data-bs-toggle="tab">Pre ICO & ICO</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#threetab" data-bs-toggle="tab">Token</a>
                                            </li>
                                        </ul>


                                        <div className='tab-content' id="mytab">
                                            <div className="tab tab-pane" id='onetab'>
                                                <div id="accordion" className='accordion'>
                                                    <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collap1">
                                                        What is Crypto Future Trading?
                                                        <FaAngleDown />
                                                    </button>
                                                    <div className="accordion_content collapse" id='collap1'>
                                                        <p className="text-muted">Crypto Future trading, also known as foreign exchange trading or currency trading, involves the buying and selling of currencies on the foreign exchange market with the aim of making a profit. The Crypto Future market is the
                                                            largest and most liquid financial market globally, where currencies are traded 24 hours a day, five days a week.
                                                        </p>
                                                    </div>


                                                    <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collap2">
                                                        How can I participate in the Crypto Future trade sale?
                                                        <FaAngleDown />
                                                    </button>
                                                    <div className="accordion_content collapse" id='collap2'>
                                                        <p className="text-muted">
                                                            The foreign exchange (Crypto Future or FX) market is a global marketplace for exchanging national currencies. Because of the worldwide reach of trade, commerce, and finance, Crypto Future markets tend to be the world's largest and most liquid asset markets. Currencies
                                                            trade against each other as exchange rate pairs.

                                                        </p>
                                                    </div>


                                                    <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collap3">
                                                        How do I benefit from the ICO Token?
                                                        <FaAngleDown />
                                                    </button>
                                                    <div className="accordion_content collapse" id='collap3'>
                                                        <p className="text-muted">

                                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                        </p>
                                                    </div>







                                                </div>
                                            </div>

                                            <div className="tab-pane" id='twotab'>
                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab4">
                                                    How do I benefit from the ICO Token?
                                                    <FaAngleDown />
                                                </button>
                                                <div className="accordion_content collapse" id='collaptab4'>
                                                    <p className="text-muted">
                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>

                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab5">
                                                    What is Ico Trading?
                                                    <FaAngleDown />

                                                </button>
                                                <div className="accordion_content collapse" id='collaptab5'>
                                                    <p className="text-muted">

                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>

                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab6">
                                                    How can I participate in the ICO Token sale?
                                                    <FaAngleDown />
                                                </button>
                                                <div className="accordion_content collapse" id='collaptab6'>
                                                    <p className="text-muted">

                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>













                                            </div>

                                            <div className="tab-pane" id='threetab'>
                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab9">
                                                    How can I participate in the ICO Token sale?
                                                    <FaAngleDown />
                                                </button>
                                                <div className="accordion_content collapse" id='collaptab9'>
                                                    <p className="text-muted">
                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>

                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab10">
                                                    How do I benefit from the ICO Token?
                                                    <FaAngleDown />

                                                </button>
                                                <div className="accordion_content collapse" id='collaptab10'>
                                                    <p className="text-muted">

                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>

                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab12">
                                                    What Tradingcurrencies can I use to purchase?
                                                    <FaAngleDown />
                                                </button>
                                                <div className="accordion_content collapse" id='collaptab12'>
                                                    <p className="text-muted">

                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>

                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab11">
                                                    What is Ico Trading?
                                                    <FaAngleDown />
                                                </button>
                                                <div className="accordion_content collapse" id='collaptab11'>
                                                    <p className="text-muted">

                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>













                                            </div>








                                        </div>











                                    </div>
                                </div>
                            </div>


                        </div>
                    </section> */}




                    <Footeres />
                </main>
            </div>

        </>
    )
}

export default Home
